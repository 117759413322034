import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MainContainer } from '../../../global/style/StyleComponent';
import { ListCardContainer, ListItemContainer } from '../act-ordinace-styled';
import { useSelector, useDispatch } from 'react-redux';
import { getDetailById } from '../../../redux-store/ordinace/actionCreator';
import Download from '../../../global/components/download/Download';

const ActOrdinanceDetails = () => {
  let { name, id } = useParams();
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.ordinance.detail);
  useEffect(() => {
    dispatch(getDetailById(id));
  }, []);
  return (
    <MainContainer>
      <ListCardContainer>
        <strong>{name.toUpperCase()}</strong>

        {detail !== undefined &&
          detail.length > 0 &&
          detail.map((item, index) => (
            <ListItemContainer key={index} onClick={() => {}}>
              <img src={require('../../../assets/garentee.png')} alt='' />
              <Download file={`${item.file}`} index={index} key={index}>
                {item.title}
              </Download>
            </ListItemContainer>
          ))}
      </ListCardContainer>
    </MainContainer>
  );
};

export default ActOrdinanceDetails;
