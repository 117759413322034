import React, { useState, useEffect } from 'react';
import {
  MainContainer,
  MapUser,
} from '../../../../global/style/StyleComponent';
import './ClientSatisfaction.css';
import ClientReview from './components/ClientReview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NextBtn, PrevBtn } from './clientStatisfactionStyled';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../../../../redux-store/home/actionCreator';

function ClientSatisfaction() {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.home.clients);
  const [indexClient, setIndexClient] = useState(0);
  useEffect(() => {
    dispatch(getClients());
  }, []);
  return (
    <MainContainer bgColor='#dedede'>
      <div className='clientStatisfactionContainer'>
        <div className='clientStatisfactionLeft'>
          {clients !== undefined &&
            clients.map((data, i) => (
              <MapUser
                key={i}
                src={
                  data.file !== '' && data.file !== 'undefined'
                    ? `https://api.taxpress.com.pk/${data.file}`
                    : 'https://via.placeholder.com/70'
                }
                alt='client'
                top={`${Math.floor(Math.random() * (65 - 25 + 1)) + 25}%`}
                right={`${Math.floor(Math.random() * (90 - 10 + 1)) + 10}%`}
              />
            ))}
        </div>
        <div className='clientStatisfactionRight'>
          <strong>Meet Client Satisfaction by using TaxPress</strong>
          <span>
            An experienced team having cumulative experience of over decades of
            best client service.
          </span>
          <div className='clientReviews'>
            <ClientReview
              data={
                clients !== undefined && clients.length > 0
                  ? clients[indexClient]
                  : {
                      comment:
                        'Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring.\n\n',
                      file: '',
                      review: 4,
                      reviewTitle: 'User friendly & Customizable',
                      clientDesignation: 'Software Engineer',
                      clientName: 'John Doe',
                    }
              }
            />
            <div className='clientReviewsBtn'>
              <PrevBtn
                onClick={() => {
                  if (clients.length <= indexClient + 1 && indexClient > 0) {
                    setIndexClient(indexClient - 1);
                  }
                }}
                isFirst={indexClient === 0}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </PrevBtn>
              <hr />
              <NextBtn
                isLast={
                  clients !== undefined
                    ? indexClient + 1 === clients.length
                    : true
                }
                onClick={() => {
                  if (clients.length !== indexClient + 1) {
                    setIndexClient(indexClient + 1);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </NextBtn>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default ClientSatisfaction;
