import React from 'react';
import { useState } from 'react';
import AxiosCall from '../../../api/AxiosCall';
import { Btn } from '../../style/StyleComponent';
import { errorToast, successToast } from '../../toast';
import { ContactFromContainer } from './contact-form-styled';

const ContactUsForm = () => {
  const [data, setData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });
  return (
    <ContactFromContainer>
      <strong>
        Have Queries? <br /> Talk to an expert
      </strong>
      <div>
        <input
          value={data.name}
          onChange={(e) => {
            setData({
              ...data,
              name: e.target.value,
            });
          }}
          type='text'
          placeholder='Name'
        />
        <input
          value={data.email}
          onChange={(e) => {
            setData({
              ...data,
              email: e.target.value,
            });
          }}
          type='email'
          placeholder='Email'
        />
        <input
          value={data.phone}
          onChange={(e) => {
            setData({
              ...data,
              phone: e.target.value,
            });
          }}
          type='number'
          placeholder='Phone'
        />
        <textarea
          maxLength={'600'}
          value={data.message}
          onChange={(e) => {
            setData({
              ...data,
              message: e.target.value,
            });
          }}
          placeholder='Message'
        />
        <Btn
          color='#194173'
          textColor='#fff'
          hoverColor='#fac201'
          hoverTextColor='#194173'
          onClick={() => {
            if (
              data.phone.length >= 11 &&
              data.email !== '' &&
              data.message !== '' &&
              data.name !== ''
            ) {
              AxiosCall.post('/query/create', {
                ...data,
                isService: 0,
                type: window.location.pathname.replace('/', ''),
              })
                .then((res) => {
                  successToast(res.data.data);
                  setData({
                    name: '',
                    phone: '',
                    email: '',
                    message: '',
                  });
                })
                .catch((err) => {
                  setData({
                    name: '',
                    phone: '',
                    email: '',
                    message: '',
                  });
                  errorToast('Unable to perform your action at this moment ');
                });
            } else {
              alert('all field are required');
            }
          }}
        >
          Send Message
        </Btn>
      </div>
    </ContactFromContainer>
  );
};

export default ContactUsForm;
