import { Box, IconButton, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Btn, MainContainer } from '../../../global/style/StyleComponent';
import { getAllBlog } from '../../../redux-store/blogs/actionCreator';
import { TeamAlertContainer } from '../../about/about-us-styled';
import '../Blogs.css';
const BlogsSection = () => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.blog.blogs);
  const [open, setOpen] = useState({
    open: false,
    item: {},
  });
  const toggle = ({ item }) => {
    setOpen({
      open: !open.open,
      item: item,
    });
  };
  const [sliceIndex, setSliceIndex] = useState(9);
  useEffect(() => {
    dispatch(getAllBlog());
  }, []);
  return (
    <MainContainer>
      <div className='cards-container'>
        {cards.slice(0, sliceIndex).map((card, index) => (
          <div key={index} className='card-blog'>
            <img
              src={'https://api.taxpress.com.pk/' + card.file}
              alt='Avatar'
              style={{ width: '100%', height: '350px', objectFit: 'cover' }}
            />
            <div className='container'>
              <div className='blog-info'>
                <h4 className='title'>
                  <b>{card.title}</b>
                </h4>
                <span>{card.date}</span>
              </div>
              <span
                onClick={() => {
                  toggle({ item: card });
                }}
                className='read-more'
                style={{ textAlign: 'right', cursor: 'pointer' }}
              >
                Read More
              </span>
            </div>
          </div>
        ))}
      </div>
      {cards.length > sliceIndex && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Btn
            id='btn-blog'
            className='read-more-button'
            onClick={() => {
              setSliceIndex(sliceIndex + 3);
            }}
          >
            Read More
          </Btn>
        </Box>
      )}
      <Modal
        open={open.open}
        onClose={() => {
          setOpen({
            open: false,
            item: {},
          });
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            backgroundColor: 'transparent',
          }}
        >
          <TeamAlertContainer>
            <IconButton
              style={{
                position: 'absolute',
                right: '5px',
                top: '5px',
              }}
              onClick={() => {
                setOpen({
                  open: false,
                  item: {},
                });
              }}
            >
              <Close />
            </IconButton>
            <img
              src={'https://api.taxpress.com.pk/' + open.item.file}
              alt=''
              srcset=''
            />
            <div>
              <strong>{open.item.title}</strong>
              <span>{open.item.date}</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                dangerouslySetInnerHTML={{ __html: open.item.paragraph }}
              ></div>
            </div>
          </TeamAlertContainer>
        </div>
      </Modal>
    </MainContainer>
  );
};

export default BlogsSection;
