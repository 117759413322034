import React from 'react';
import HeroSections from '../../../global/components/hero-section/HeroSections';
import BlogsSection from './blogs-section/BlogsSection';

const BlogsResearch = () => {
  return (
    <>
      <HeroSections pageName={'Research & Insight'} />
      <BlogsSection />
    </>
  );
};

export default BlogsResearch;
