import React from 'react';
import { Container, MainContainer } from '../../../global/style/StyleComponent';
import { RegistrationServiceHead, SalesTaxContainer } from '../services-styled';

const SalesTaxService = () => {
  return (
    <MainContainer id='salesTaxContainer' paddingVertical='90px'>
      <RegistrationServiceHead>
        <h1>Sales Tax Services</h1>
        <hr />
      </RegistrationServiceHead>
      <SalesTaxContainer>
        <div>
          <h2>Over 30 years of cumulative experience in Sales Tax Practice</h2>
          <p>
            Our team possess a rich experience of filing federal and provincial
            sales tax returns and provision of ancillary compliance services
          </p>
          <div>
            <div>
              <Container
                direction='row'
                justifyContent='flex-start'
                alignItem='center'
                marginTop='20px'
              >
                <img
                  src={require('../../../assets/garentee.png')}
                  alt='garentee'
                  style={{
                    marginRight: '20px',
                  }}
                />
                <strong>ST Registration</strong>
              </Container>
              <span>
                Our clients deserve the best and our team tries hard to make
                your sales tax registration process simple, speedy and reliable
              </span>
            </div>
            <div>
              <Container
                direction='row'
                justifyContent='flex-start'
                alignItem='center'
                marginTop='20px'
              >
                <img
                  src={require('../../../assets/garentee.png')}
                  alt='garentee'
                  style={{
                    marginRight: '20px',
                  }}
                />
                <strong>ST Return</strong>
              </Container>
              <span>
                Be a sales tax return filer and get entitled to claim all
                admissible taxes paid on purchase of goods and services
              </span>
            </div>
          </div>
        </div>
        <div id='progreeBarSale'>
          <img src={require('../../../assets/barchart.png')} alt='' />
          <span>our progress</span>
        </div>
      </SalesTaxContainer>
    </MainContainer>
  );
};

export default SalesTaxService;
