import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Download from '../../../../global/components/download/Download';
import { Btn } from '../../../../global/style/StyleComponent';
import { getResearchAndInsight } from '../../../../redux-store/blogs/actionCreator';
import './BlogSection.css';

const BlogsSection = () => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.blog.researchAndInsightBlog);
  const [sliceIndex, setSliceIndex] = useState(9);
  useEffect(() => {
    dispatch(getResearchAndInsight());
  }, []);

  return (
    <Container sx={{ minHeight: '50vh' }} className='blogsSectionContainer'>
      <div className='cards-container'>
        {cards.slice(0, sliceIndex).map((card, index) => (
          <div key={index} className='card_insight'>
            <img
              src={`${process.env.REACT_APP_FILE_DOMAIN}${card.thumbnail}`}
              alt='Avatar'
              style={{
                width: '100%',
                objectFit: 'cover',
                height: '200px',
                minHeight: '200px',
              }}
            />
            <div className='card_insight_container'>
              <div className='blog-info'>
                <h4 className='title'>
                  <b>{card.title}</b>
                </h4>
                <p
                  style={{
                    margin: 0,
                  }}
                  className='description'
                >
                  {card.shortParagraph}
                </p>
                <p
                  style={{
                    margin: 0,
                    marginTop: 5,
                  }}
                >
                  {card.date}
                </p>
              </div>
              <Download file={card.file} index={index}>
                Download
              </Download>
            </div>
          </div>
        ))}
      </div>
      {cards.length > sliceIndex && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Btn
            id='btn-blog'
            className='read-more-button'
            onClick={() => {
              setSliceIndex(sliceIndex + 3);
            }}
          >
            Read More
          </Btn>
        </Box>
      )}
    </Container>
  );
};

export default BlogsSection;
