import AxiosCall from '../../api/AxiosCall';
import {
  setNormalCategories,
  setNormalServices,
  setOverSeaCategories,
  setOverSeaService,
} from './serviceReducer';

export const getNormalServicesCategory = () => (dispatch) => {
  AxiosCall.get('/registrationServiceType/getTypesByServiceType/Normal')
    .then((res) => {
      dispatch(setNormalCategories([{ title: 'All' }, ...res.data.data]));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getNormalServices = (category) => (dispatch) => {
  AxiosCall.get(`/registrationServiceDetail/getAllServicesByType/Normal`)
    .then((res) => {
      if (category === 'All') {
        dispatch(setNormalServices(res.data.data));
      } else {
        dispatch(
          setNormalServices(
            res.data.data.filter(
              (item) => item.type.toLowerCase() === category.toLowerCase()
            )
          )
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOverSeaServicesCategory = () => (dispatch) => {
  AxiosCall.get('/registrationServiceType/getTypesByServiceType/Overseas')
    .then((res) => {
      dispatch(setOverSeaCategories([{ title: 'All' }, ...res.data.data]));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOverSeaServices = (category) => (dispatch) => {
  AxiosCall.get(`/registrationServiceDetail/getAllServicesByType/Overseas`)
    .then((res) => {
      if (category === 'All') {
        dispatch(setOverSeaService(res.data.data));
      } else {
        dispatch(
          setOverSeaService(
            res.data.data.filter(
              (item) => item.type.toLowerCase() === category.toLowerCase()
            )
          )
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
