import React from 'react';
import {
  Btn,
  Container,
  MainContainer,
} from '../../../global/style/StyleComponent';
import { AboutUsImage, Heading } from '../../about/about-us-styled';
import { useDispatch } from 'react-redux';
import { setAppointment } from '../../../redux-store/home/homeReducer';

const ConsultAnExpert = () => {
  const dispatch = useDispatch();
  return (
    <MainContainer
      id='consult-an-expert-container'
      bgColor='#373f68'
      paddingLeft={'0px'}
    >
      <Container>
        <AboutUsImage src={require('../../../assets/consult.png')} alt='' />
        <Container
          id='consult-an-expert-right'
          marginLeft={'50px'}
          direction={'column'}
          color='#fff'
          justifyContent={'center'}
        >
          <Heading>Consult an Expert</Heading>
          <p>
            Provide our team an opportunity to help and serve you in managing
            all your business and taxation needs
          </p>
          <h3
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            Request a call back
          </h3>
          <p>
            Our dedicated call back operators serve our clients round the clock.
            We believe in provision of services on the Go.
          </p>
          <Btn
            id='request-a-call-back-btn'
            marginTop='20px'
            textColor='#000'
            alignSelf='flex-end'
            onClick={() => dispatch(setAppointment())}
          >
            Request a call back
          </Btn>
          <h3
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            Fix Meeting
          </h3>
          <p>
            Pick up a meeting slot of your choice and let our experts serve you
          </p>
          <Btn
            id='request-a-call-back-btn'
            marginTop='20px'
            textColor='#000'
            alignSelf='flex-end'
            onClick={() => dispatch(setAppointment())}
          >
            Fix Meeting
          </Btn>
        </Container>
      </Container>
    </MainContainer>
  );
};

export default ConsultAnExpert;
