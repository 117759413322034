import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Btn,
  Container,
  ErrorText,
  Input,
  LoginRegistrationContainer,
  MainContainer,
} from "../../global/style/StyleComponent";
import { userLogin } from "../../redux-store/user/actionCreator";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "Home > Login";

  return (
    <>
      <div
        style={{
          borderTop: "1px solid black",
          // height: "70px",
          backgroundColor: "#323232",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "5px" }}>
          {/* <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Login</h1> */}
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>
      <MainContainer
        paddingVertical={"90px"}
        justifyContent={"center"}
        alignItem={"center"}
      >
        <LoginRegistrationContainer role="form">
          <h2 style={{ color: "black", marginBottom: 0 }}>Welcome to MBJ</h2>
          <p style={{ textAlign: "center", marginTop: 0, color: "black" }}>
            A delectus expedita sit, maiores voluptas aspernatur. <br />
            bcaecati culpa officia voluptatibus magnam fugit <br /> non quia
            mollitia amet error sed quod. <br />
          </p>
          <h3 style={{ textAlign: "center", color: "black" }}>
            Login to Our System
          </h3>
          <Input
            id="email"
            label="Email"
            placeholder="User Name"
            onChange={(e) => setEmail(e.target.value)}
          />
          <ErrorText style={{ color: "black" }}>{error.email}</ErrorText>
          <Input
            id="password"
            label="Password"
            type={"password"}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <ErrorText style={{ color: "black" }}>{error.password}</ErrorText>
          <Container alignItem={true} marginBottom="10px">
            <Container
              justifyContent="flex-start"
              alignItem={true}
              width="auto"
            >
              <Input
                id="remember"
                width="auto"
                type={"checkbox"}
                marginBottom="0px"
              />
              <label htmlFor="remember" style={{ color: "black" }}>
                Remember me
              </label>
            </Container>
            <span style={{ cursor: "pointer", color: "black" }}>
              Forget Password
            </span>
          </Container>
          <Btn
            type="submit"
            id="login-btn"
            onClick={() => {
              var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (email === "") {
                setError({
                  ...error,
                  email: "Email is required",
                  password: "",
                });
              } else if (!email.match(mailFormat)) {
                setError({
                  ...error,
                  email: "Email is not valid",
                  password: "",
                });
              } else if (password === "") {
                setError({ email: "", password: "Password is required" });
              } else {
                setError({ email: "", password: "" });
                dispatch(
                  userLogin(
                    {
                      username: email,
                      password: password,
                    },
                    navigate
                  )
                );
              }
            }}
            fontSize="18px"
          >
            Login
          </Btn>

          <span style={{ color: "black" }}>Don't have account yet?</span>
          <Link to="/registration" style={{ color: "black" }}>
            Sign Up
          </Link>
        </LoginRegistrationContainer>
      </MainContainer>
    </>
  );
};

export default Login;
