import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  normalServices: [],
  normalCategories: [],
  overSeaService: [],
  overSeaCategories: [],
};

export const serviceSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setNormalServices: (state, action) => {
      state.normalServices = action.payload;
    },
    setOverSeaService: (state, action) => {
      state.overSeaService = action.payload;
    },
    setNormalCategories: (state, action) => {
      state.normalCategories = action.payload;
    },
    setOverSeaCategories: (state, action) => {
      state.overSeaCategories = action.payload;
    },
  },
});

export const {
  setNormalServices,
  setOverSeaService,
  setNormalCategories,
  setOverSeaCategories,
} = serviceSlice.actions;
export default serviceSlice.reducer;
