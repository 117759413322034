import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Btn,
  Container,
  ErrorText,
  Input,
  LoginRegistrationContainer,
  MainContainer,
} from "../../global/style/StyleComponent";
import { userLogin } from "../../redux-store/user/actionCreator";
import "./Login.css";

const RequestCase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "Home > Login";

  return (
    <>
      <MainContainer
        paddingVertical={"90px"}
        justifyContent={"center"}
        alignItem={"center"}
      >
        <LoginRegistrationContainer role="form">
          <h2 style={{ textAlign: "center", marginTop: 0, color: "black" }}>
            Request case
          </h2>
          <Input
            // id="email"
            style={{marginBottom:'20px'}}
            label="Case No"
            placeholder="Case No"
            // onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            // id="email"
            style={{marginBottom:'20px'}}
            label="Name"
            placeholder="Name"
            // onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            // id="email"
            style={{marginBottom:'20px'}}
            label="Phone"
            placeholder="Phone"
            // onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            // id="email"
            style={{marginBottom:'20px'}}
            label="Email"
            placeholder="User Name"
            // onChange={(e) => setEmail(e.target.value)}
          />
          {/* <ErrorText style={{color:'black'}}>{error.email}</ErrorText> */}

          <Btn
            type="submit"
            id="login-btn"
            onClick={() => {
              var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (email === "") {
                setError({
                  ...error,
                  email: "Email is required",
                  password: "",
                });
              } else if (!email.match(mailFormat)) {
                setError({
                  ...error,
                  email: "Email is not valid",
                  password: "",
                });
              } else if (password === "") {
                setError({ email: "", password: "Password is required" });
              } else {
                setError({ email: "", password: "" });
                dispatch(
                  userLogin(
                    {
                      username: email,
                      password: password,
                    },
                    navigate
                  )
                );
              }
            }}
            fontSize="18px"
          >
            Request
          </Btn>
        </LoginRegistrationContainer>
      </MainContainer>
    </>
  );
};

export default RequestCase;
