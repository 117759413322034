import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  act: [],
  ordinance: [],
  rules: [],
  detail: [],
};

export const ordinaceSlice = createSlice({
  name: 'ordinace',
  initialState,
  reducers: {
    setAct: (state, action) => {
      state.act = action.payload;
    },
    setOrdinance: (state, action) => {
      state.ordinance = action.payload;
    },
    setRules: (state, action) => {
      state.rules = action.payload;
    },
    setDetails: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const { setAct, setOrdinance, setRules, setDetails } =
  ordinaceSlice.actions;

export default ordinaceSlice.reducer;
