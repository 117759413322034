import React, { useEffect } from 'react';
import {
  Container,
  FloatingContainer,
} from '../../global/style/StyleComponent';
import ContactHeroCards from './components/contactHero/components/ContactHeroCards';
import GetInTouch from './components/getInTouch/GetInTouch';
import Location from '../../assets/location-icon.png';
import Call from '../../assets/call-icon.png';
import Email from '../../assets/email-icon.png';
import HeroSections from '../../global/components/hero-section/HeroSections';
import './ContactPage.css';

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id='contactPageHeroSection'>
        <HeroSections pageName={'Contact'} />
        <FloatingContainer
          id='contactPageCardContainer'
          left={'150px'}
          right={'150px'}
          bottom={'-98%'}
        >
          <Container>
            <ContactHeroCards
              icon={Location}
              heading={'Our Location'}
              text={[
                'Office 112, South Tower, Stock Exchange Building, Near Aiwan e Iqbal, Lahore',
              ]}
              url={`https://maps.app.goo.gl/ka5rt3ajkwPzfLuaA`}
            />
            <ContactHeroCards
              icon={Call}
              heading={'Call Anytime'}
              text={['+923328626415']}
              url={'tel:+923328626415'}
            />
            <ContactHeroCards
              icon={Email}
              heading={'Send Email'}
              text={['info@taxpress.com.pk']}
              url={'mailto:info@taxpress.com.pk'}
            />
          </Container>
        </FloatingContainer>
      </div>
      <GetInTouch />
      <div id='contactPageMap'>
        <iframe
          title='GMap'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212.8546984858286!2d74.32951258560522!3d31.55936953670903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904b49c54459f%3A0x5cb0f6a877a636d1!2sPakistan%20Stock%20Exchange%20PSX%20(Regional%20Office)!5e0!3m2!1sen!2s!4v1696769852128!5m2!1sen!2s'
          width='100%'
          height='100%'
        />
      </div>
    </>
  );
};

export default ContactPage;
