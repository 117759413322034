import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Btn,
  Container,
  ErrorText,
  Input,
  LoginRegistrationContainer,
} from "../../global/style/StyleComponent";
import { userLogin } from "../../redux-store/user/actionCreator";
import "./lexpress.css";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div
        style={{
          minHeight: "68vh",
          padding: "20px",
          backgroundImage:
            "url(https://t3.ftcdn.net/jpg/07/52/70/26/240_F_752702693_UcYNYcWgDY5fEwG3xN7j5iwFIPQGC3jx.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {/* <div></div> */}
        
          <div className="lex-form">
            <h2
              style={{ color: "white", textAlign: "center", marginBottom: 0 }}
            >
              Welcome to MBJ
            </h2>
            <p style={{ color: "white", textAlign: "center", marginTop: 0, marginBottom:0 }}>
              A delectus expedita sit, maiores voluptas aspernatur. <br />
              bcaecati culpa officia voluptatibus magnam fugit
            </p>
            <h3 style={{ textAlign: "center", color: "white" }}>
              Login to Our System
            </h3>

            <Input
              id="email"
              label="Email"
              placeholder="User Name"
              onChange={(e) => setEmail(e.target.value)}
            />
            <ErrorText>{error.email}</ErrorText>
            <Input
              id="password"
              label="Password"
              type={"password"}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <ErrorText>{error.password}</ErrorText>
            <Container alignItem={true} marginBottom="10px">
              <Container
                justifyContent="flex-start"
                alignItem={true}
                width="auto"
              >
                <Input
                  id="remember"
                  width="auto"
                  type={"checkbox"}
                  marginBottom="0px"
                />
                <label htmlFor="remember">Remember me</label>
              </Container>
              <span style={{ cursor: "pointer" }}>Forget Password</span>
            </Container>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Btn
                type="submit"
                id="login-btn"
                onClick={() => {
                  var mailFormat =
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                  if (email === "") {
                    setError({
                      ...error,
                      email: "Email is required",
                      password: "",
                    });
                  } else if (!email.match(mailFormat)) {
                    setError({
                      ...error,
                      email: "Email is not valid",
                      password: "",
                    });
                  } else if (password === "") {
                    setError({ email: "", password: "Password is required" });
                  } else {
                    setError({ email: "", password: "" });
                    dispatch(
                      userLogin(
                        {
                          username: email,
                          password: password,
                        },
                        navigate
                      )
                    );
                  }
                }}
                fontSize="18px"
              >
                Login
              </Btn>
            </div>
            <span
              style={{ color: "white", textAlign: "center", marginTop: "10px" }}
            >
              Don't have account yet?
            </span>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to="/registration"
                style={{
                  textDecoration: "none",
                  color: isHovered ? "White" : "black", // Change 'blue' and 'black' to your desired colors
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Sign Up
              </Link>
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default HomePage;
