import styled from 'styled-components';

export const ActionBtnCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #373f68;
  padding: 25px;
  min-width: 200px;
  color: #fff;
  user-select: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1500px) {
    font-size: 16px;
  }
  @media (max-width: 1300px) {
    font-size: 16px;
  }
  @media (max-width: 1100px) {
    font-size: 16px;
  }
  @media (max-width: 960px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 15px;
  }
  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    text-align: center;
  }
  @media (max-width: 375px) {
    font-size: 10px;
  }
`;

export const LibraryCardsContainer = styled.div`
  width: 100%;
  margin: 2em 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ gap }) => gap || '1em'};
`;

export const LibraryCountCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #ffd467;
  padding: 1em;
  flex: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  & > strong {
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    flex: 1;
    & > strong {
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    & > strong {
      font-size: 12px;
    }
  }
`;

export const LibraryListsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-gap: 2em;
  margin: 3em 0;
  & > div:first-child {
    grid-column: span 2;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    & > div:first-child {
      grid-column: span 1;
    }
  }
`;

export const LibraryListCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #ccc;
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  & > strong {
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding: 1em;
  }
  & > div {
    padding: 0.7em 2em;
    cursor: pointer;
    &: hover {
      text-decoration: underline;
    }
  }
`;

export const ResearchHeading = styled.h2`
  font-size: 30px;
  margin: 10px 0px;
  margin-right: 10px;
  text-align: left;
  @media (max-width: 1130px) {
    font-size: 40px;
  }

  @media (max-width: 940px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const ResearchParagraph = styled.p`
  font-size: 25px;
  text-align: justify;
  @media (max-width: 1130px) {
    font-size: 20px;
  }
  @media (max-width: 940px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ResearchContainer = styled.div`
  @media (max-width: 600px) {
    & #researchContainerHeading {
      flex-direction: column;
    }
    & #countHeading {
      display: none;
    }
  }
`;
