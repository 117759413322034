import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/MBJ.png";
import { setAppointment } from "../../../redux-store/home/homeReducer";
import { Btn, MainContainer } from "../../style/StyleComponent";
import Appointment from "../appointment/Appointment";
import "./NavHeader.css";

const NavHeader = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector((state) => state.users.user);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  return (
    <div style={{ backgroundColor: "#9e742c" }}>
      <MainContainer
        id="navHeaderContainer"
        bgColor="#9e742c"
        flexDirection="row"
        paddingVertical="15px"
        justifyContent="space-between"
        alignItem="center"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div id="menuIcon" onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={faBars} color="#9e742c" />
        </div>

        <Link id="logoHead" to={isLoggedIn ? "/library/dashboard" : "/"}>
          <img
            style={{ height: "50px", width: "auto" }}
            src={Logo}
            alt="Logo"
          />
        </Link>
        {width > 550 || isOpen ? (
          <div className="navItemsContainer">
            <Link to={"/library/case-law"} className="navBtn">
              Case Laws
            </Link>
            <Link to="/library/statutes" className="navBtn">
              Statutes
            </Link>
            {/* <div>
              <div onClick={handleClick} className="navBtn">
                Tools & Resources
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigator("/salary-tax-calculator");
                    handleClose();
                  }}
                >
                  Salary-calculator
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    navigator("/login");
                    handleClose();
                  }}
                >
                  Tax Portal
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigator("/blogs");
                    handleClose();
                  }}
                >
                  Blog
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigator("/research-and-insight");
                    handleClose();
                  }}
                >
                  Research & Insight
                </MenuItem>
              </Menu>
            </div> */}
            {/* <Link
              to={
                user?.user_role === "library" ? "/case-law-library" : "/login"
              }
              className="navBtn"
            >
              Case Law Library
            </Link> */}
            <Link to="/library/notification" className="navBtn">
              Notifications
            </Link>
            <Link to="/library/dictionary" className="navBtn">
              Dictionary
            </Link>
            <Link to="/library/dictionary" className="navBtn">
              Contact
            </Link>
          </div>
        ) : null}
        {!isLoggedIn && (
          <Btn
            id="appointmentBtn"
            width="fit-content"
            fontSize="16px"
            onClick={() => {
              // dispatch(setAppointment());
              navigator("/registration");
            }}
          >
            Registeration
          </Btn>
        )}
      </MainContainer>
      <Appointment />
    </div>
  );
};

export default NavHeader;
