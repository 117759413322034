import React from 'react';
import { MainContainer } from '../../style/StyleComponent';
import ContactUsForm from '../contact-us-form/ContactUsForm';
import CategoryBar from './components/CategoryBar';
import PriceCard from './components/PriceCard';
import {
  CategoryList,
  ServerAndFeeContainer,
  ServicesAndFeeBody,
  ServicesAndFeeHead,
} from './services-and-fee-styled';

const ServicesAndFee = ({
  activeCategory,
  setActiveCategory,
  categories,
  data = [],
}) => {
  return (
    <MainContainer id='service' flexDirection='row' paddingVertical='90px'>
      <ServerAndFeeContainer>
        <ServicesAndFeeHead>
          <h1>Service And Fee</h1>
          <span>
            Our goal is to provide high-quality, reliable service at a
            reasonable fee. Our fees are market competitive and determined by
            each professional’s level of experience.
          </span>
        </ServicesAndFeeHead>
        <ServicesAndFeeBody>
          <CategoryList>
            <CategoryBar
              setActiveCategory={setActiveCategory}
              active={activeCategory}
              categories={categories}
            />
            {data !== undefined &&
              data.map((item, index) => (
                <PriceCard
                  key={index}
                  title={item.title}
                  servicesList={JSON.parse(item.highlights)}
                  Fee={item.fee}
                  completionTime={item.completionTime}
                />
              ))}
          </CategoryList>
          <ContactUsForm />
        </ServicesAndFeeBody>
      </ServerAndFeeContainer>
    </MainContainer>
  );
};

export default ServicesAndFee;
