import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, MainContainer } from "../../../../global/style/StyleComponent";
import {
  getAllNotificationType,
  getAllStatutes,
  getSearchNotification,
} from "../../../../redux-store/library/actionCreator";
import {
  SearchButtonContainer,
  SearchContainer,
} from "../../styles/LibraryStyled";
import NotificationList from "./NotificationList";

const NotificationSearch = () => {
  const allStatutes = useSelector((state) => state.library.allStatutes);
  const allNotificationType = useSelector(
    (state) => state.library.allNotificationType
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStatutes());
    dispatch(getAllNotificationType());
  }, []);
  const [search, setSearch] = useState({
    notification_type_id: "",
    sro_no: "",
    year: "",
    dated: "",
    law_or_statute_id: "",
  });
  return (
    <MainContainer paddingVertical={"90px"}>
      <h1>Notification</h1>

      <SearchContainer row={3}>
        <select
          name="notificationType"
          id="notificationType"
          style={{
            gridColumn: "1 / span 2",
            borderRadius: "50px",
            padding: "10px",
          }}
          onChange={(e) =>
            setSearch({ ...search, notification_type_id: e.target.value })
          }
        >
          <option value="">Notification Type</option>
          {allNotificationType.map((statute, index) => (
            <option key={index} value={statute.id}>
              {statute.title}
            </option>
          ))}
        </select>
        <Input
          placeholder="SRO No"
          type={"number"}
          onChange={(e) =>
            setSearch({ ...search, sro_no: e.target.value.trim() })
          }
        />
        <Input
          type="text"
          placeholder="Year"
          onChange={(e) =>
            setSearch({ ...search, year: e.target.value.trim() })
          }
        />
        {/* <Input
            type='text'
            placeholder='Text Search 1'
            onChange={(e) =>
              setSearch({ ...search, dated: e.target.value.trim() })
            }
          />
          <Input
            type='text'
            placeholder='Text Search 2'
            onChange={(e) =>
              setSearch({
                ...search,

              })
            }
          /> */}
        <Input
          type="date"
          placeholder="Dated"
          onChange={(e) =>
            setSearch({
              ...search,
              dated: new Date(e.target.value).getTime(),
            })
          }
        />
        <Input
          type="text"
          placeholder="Section"
          onChange={(e) =>
            setSearch({
              ...search,
              section: e.target.value.trim(),
            })
          }
        />
        <select
          name="law"
          id="law"
          style={{
            gridColumn: "3 / span 2",
            borderRadius: "50px",
            padding: "10px",
          }}
          onChange={(e) => {
            setSearch({ ...search, law_or_statute_id: e.target.value });
          }}
        >
          <option value="">Law/Statute</option>
          {allStatutes.map((statute, index) => (
            <option key={index} value={statute.id}>
              {statute.law_or_statute}
            </option>
          ))}
        </select>
        <SearchButtonContainer gridColumn={" 1 / 4 span "}>
          <button
            style={{ backgroundColor: "#9e742c", borderRadius:'50px' }}
            onClick={() => {
              dispatch(getSearchNotification(search));
            }}
          >
            Search
          </button>
        </SearchButtonContainer>
      </SearchContainer>
      <NotificationList />
    </MainContainer>
  );
};

export default NotificationSearch;
