import styled from 'styled-components';

export const AboutUsImage = styled.img`
  flex: 1;
  width: 50%;
  object-fit: cover;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const SubHeading = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const Heading = styled.h1`
  font-size: 55px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: capitalize;
  margin-top: 0px;
  z-index: 1;
  @media (max-width: 1440px) {
    font-size: 35px;
  }
  @media (max-width: 1150px) {
    font-size: 30px;
  }
`;

export const GridList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  margin-top: 20px;
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const GridListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > img {
    margin-right: 10px;
  }
  & > span {
    font-size: 14px;
  }
`;

export const HowWeWorkListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  & > img {
    margin-bottom: 15px;
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  & > strong {
    text-transform: uppercase;
    font-size: 21px;
    text-align: center;
  }
  & > span {
    text-align: center;
  }
  @media (max-width: 1440px) {
    & > strong {
      font-size: 18px;
    }
    & > img {
      width: 40px;
      height: 40px;
    }
  }
  @media (max-width: 1050px) {
    & > strong {
      font-size: 16px;
    }
    & > img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const TeamHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span {
    font-size: 14px;
    color: #8590a5;
    text-transform: uppercase;
    font-weight: lighter;
  }

  & > h1 {
    margin: 0;
    font-size: 45px;
    font-weight: bold;
    color: #325b99;
  }
  & > hr {
    width: 35%;
    border: 1px solid #8590a5;
  }
  & > strong {
    width: 60%;
    text-align: center;
  }
  @media (max-width: 1240px) {
    & > h1 {
      font-size: 35px;
    }
    & > strong {
      width: 80%;
      font-size: 16px;
    }
  }
  @media (max-width: 1050px) {
    & > h1 {
      font-size: 30px;
    }
    & > strong {
      width: 90%;
      font-size: 14px;
    }
  }
`;

export const TeamList = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  position: relative;
  margin-top: 40px;
  & > div:nth-child(2) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const TeamListItem = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  height: 450px !important;
  min-width: 350px !important;
  margin: 25px 15px;
  & > img {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    & > #onHover {
      display: flex;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #6e7390;
      opacity: 0.5;
    }
  }
  @media (max-width: 860px) {
    width: 350px;
    margin-bottom: 50px;
  }
`;

export const TeamListItemBottom = styled.div`
  position: absolute;
  bottom: -30px;
  z-index: 100;
  display: flex;
  width: 60%;
  user-select: none;
  cursor: pointer;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fec028;
  & > strong {
    font-size: 21px;
    font-weight: bold;
  }
  & > span {
    font-size: 14px;
  }
  @media (max-width: 1000px) {
    & > strong {
      font-size: 18px;
    }
    & > span {
      font-size: 12px;
    }
  }
`;

export const TeamHover = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 100;
  align-items: center;
  justify-content: center;
  display: none;
  & > a {
    padding: 20px;
    background-color: #fff;
    border-radius: 50%;
    &:hover {
      background-color: #fec028;
    }
    &:nth-child(1) {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }

  & > a > img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  @media (max-width: 1240px) {
    & > a {
      padding: 15px;
    }
    & > a > img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const CountersContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 50px 150px;
  justify-content: space-between;
  background-image: url(${require('../../assets/about-us-counter-section.png')});
  @media (max-width: 1500px) {
    padding: 50px 120px;
  }
  @media (max-width: 1200px) {
    padding: 50px 100px;
  }
  @media (max-width: 800px) {
    padding: 50px 70px;
  }
  @media (max-width: 630px) {
    padding: 50px 50px;
  }
  @media (max-width: 500px) {
    display: none;
    padding: 30px;
  }
`;

export const CounterItem = styled.div`
  padding: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  & > strong {
    font-size: 45px;
    font-weight: bold;
  }
  & > hr {
    width: 80%;
    border: 1px solid #000;
  }
  & > span {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  @media (max-width: 1240px) {
    height: 150px;
    width: 150px;
    & > strong {
      font-size: 35px;
    }
    & > hr {
      width: 60%;
    }
    & > span {
      font-size: 14px;
    }
  }
  @media (max-width: 978px) {
    height: 120px;
    width: 120px;
    & > strong {
      font-size: 30px;
    }
    & > hr {
      width: 70%;
    }
    & > span {
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {
    height: 100px;
    width: 100px;
    & > strong {
      font-size: 25px;
    }
  }
  @media (max-width: 620px) {
    height: 80px;
    width: 80px;
    & > strong {
      font-size: 20px;
    }
    & > span {
      font-size: 10px;
    }
  }
`;

export const TeamAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 50px;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  width: 60%;
  position: relative;
  & > img {
    width: 100%;
    height: 450px;
    object-fit: contain;
    margin-right: 20px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    & > strong {
      font-size: 3em;
    }
    & > span {
      font-size: 1.2em;
    }
    & > p {
      font-size: 1.2em;
      padding-bottom: 50px;
    }
    & > div {
      display: flex;

      & > a {
        display: flex;
        align-items: center;
        background-color: #fff;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 100%;
        &:first-child {
          margin-right: 20px;
        }
        &:last-child {
          margin-left: 20px;
        }
        & > img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;
