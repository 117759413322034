import styled from 'styled-components';

export const ReturnUsaContainer = styled.div`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 65%;
  @media (max-width: 1250px) {
    width: 75%;
  }
  @media (max-width: 1080px) {
    width: 85%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ReturnUsaHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h1 {
    margin: 0px;
    font-size: 45px;
    color: #373f68;
    text-align: center;
    text-transform: uppercase;
  }
  & > hr {
    width: 20%;
    border: 1px solid #fcc028;
  }
  & > span {
    font-size: 14px;
    color: #373f68;
    margin-top: 10px;
    font-weight: 600;
    width: 65%;
    text-align: center;
  }
  @media (max-width: 1080px) {
    & > h1 {
      font-size: 35px;
    }
    & > span {
      font-size: 12px;
      width: 80%;
    }
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 30px;
    }
    & > span {
      width: 90%;
    }
  }
  @media (max-width: 450px) {
    & > h1 {
      font-size: 25px;
      text-align: center;
    }
    & > span {
      width: 100%;
      font-size: 10px;
    }
  }
`;

export const ReturnUsaService = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
  & > hr {
    border: 1px solid #373f68;
    margin: 0px 25px;
  }
  & a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000;
    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 20px;
    }
    & > h3 {
      margin: 0px;
      font-size: 21px;
      text-transform: capitalize;
    }
    & > p {
      font-size: 14px;
      text-align: center;
    }
  }
  @media (max-width: 1080px) {
    & > hr {
      margin: 0px 10px !important;
    }
    & a {
      & > img {
        width: 40px;
        height: 40px;
      }
      & > h3 {
        font-size: 18px;
      }
      & > p {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 768px) {
    & a {
      & > img {
        width: 30px;
        height: 30px;
      }
      & > h3 {
        font-size: 14px;
      }
      & > p {
        font-size: 10px;
      }
    }
  }
`;

export const OverSeasPakistanisContainer = styled.div`
  background-image: url(${require('../../assets/How-to-File-Income-Tax-Return.png')});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 90px 150px;

  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: url(${require('../../assets/service-girl.png')});
    background-repeat: no-repeat;
    background-position: top;
    background-size: auto;
  }

  & > div {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: #000;
    & > h1 {
      font-size: 45px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    & > span {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: justify;
    }
    & a {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: #000;
      & > img {
        margin-right: 20px;
      }
      & > div {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-size: 21px;
          text-transform: uppercase;
          margin-bottom: 5px;
          margin-top: 0px;
        }
        & > p {
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 600;
          margin: 0px;
          text-align: justify;
        }
      }
    }
  }
  @media (max-width: 1350px) {
    padding: 90px 120px;

    &::after {
      width: 50%;
      height: 100%;
      background-size: contain;
    }
  }
  @media (max-width: 1080px) {
    padding: 90px 100px;
  }
  @media (max-width: 980px) {
    padding: 80px 80px;
    &::after {
      background-size: cover;
    }
    & > div {
      & > h1 {
        font-size: 35px;
      }
      & > span {
        font-size: 14px;
      }
      & a {
        & > div {
          & > h3 {
            font-size: 18px;
          }
          & > p {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media (max-width: 830px) {
    padding: 70px 70px;
    &::after {
      background-size: cover;
    }
    & > div {
      & > h1 {
        font-size: 30px;
      }
      & > span {
        font-size: 12px;
      }
      & a {
        & > div {
          & > h3 {
            font-size: 16px;
          }
          & > p {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media (max-width: 720px) {
    padding: 60px 60px;
    &::after {
      background-size: cover;
    }
    & > div {
      & > h1 {
        font-size: 25px;
      }
      & > span {
        font-size: 10px;
      }
      & a {
        & > div {
          & > h3 {
            font-size: 14px;
          }
          & > p {
            font-size: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 650px) {
    padding: 50px 50px;
  }
  @media (max-width: 620px) {
    padding: 40px 40px;
  }
  @media (max-width: 600px) {
    padding: 30px 30px;
    &::after {
      background: none;
      display: none;
    }
    & > div {
      width: 100%;
      & a {
        & > img {
          width: 30px;
          height: auto;
        }
      }
    }
  }
`;
