import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, MainContainer } from "../../../../global/style/StyleComponent";
import {
  getAllStatutes,
  getSearchStatutes,
} from "../../../../redux-store/library/actionCreator";
import {
  SearchButtonContainer,
  SearchContainer,
} from "../../styles/LibraryStyled";
import StatuesList from "./StatuesList";

const StatutesSearch = () => {
  const allStatutes = useSelector((state) => state.library.allStatutes);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllStatutes());
  }, []);

  const [search, setSearch] = useState({
    law_or_statute: "",
  });

  return (
    <MainContainer paddingVertical={"90px"}>
      <h1>Statutes</h1>

      <SearchContainer row={4}>
        <select
          name="law"
          id="law"
          style={{
            gridColumn: "1 / span 2",
            borderRadius: "50px",
            padding: "10px",
          }}
          onChange={(e) => {
            setSearch({ ...search, law_or_statute: e.target.value.trim() });
          }}
        >
          <option value="">Law/Statute</option>
          {allStatutes.map((statute, index) => (
            <option key={index} value={statute.law_or_statute.trim()}>
              {statute.law_or_statute}
            </option>
          ))}
        </select>

        <SearchButtonContainer gridColumn={" 1 / 4 span "}>
          <button
            style={{ backgroundColor: "#9e742c" }}
            onClick={() => {
              dispatch(getSearchStatutes(search));
            }}
          >
            Search
          </button>
        </SearchButtonContainer>
      </SearchContainer>
      <StatuesList />
    </MainContainer>
  );
};

export default StatutesSearch;
