import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  researchAndInsight: [],
  team: [],
  background: [],
  appointmentSlot: [],
  appointment: false,
  clients: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setTopResearch: (state, action) => {
      state.researchAndInsight = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    setBackground: (state, action) => {
      state.background = action.payload;
    },
    setAppointmentSlot: (state, action) => {
      state.appointmentSlot = action.payload;
    },
    setAppointment: (state, _) => {
      state.appointment = !state.appointment;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
  },
});
export const {
  setTopResearch,
  setTeam,
  setBackground,
  setAppointmentSlot,
  setAppointment,
  setClients,
} = homeSlice.actions;
export default homeSlice.reducer;
