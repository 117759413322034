import React, { useEffect } from 'react';
import HeroSections from '../../global/components/hero-section/HeroSections';
import { MainContainer } from '../../global/style/StyleComponent';
import ListCard from './components/ListCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAll } from '../../redux-store/ordinace/actionCreator';
import './ActOrdinaceRule.css';

const ActOrdinaceRule = () => {
  const dispatch = useDispatch();
  const Act = useSelector((state) => state.ordinance.act);
  const Ordinance = useSelector((state) => state.ordinance.ordinance);
  const Rules = useSelector((state) => state.ordinance.rules);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAll());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSections pageName={'Act/Ordinance/Rules'} />
      <MainContainer id='actOrdinanceContainer' flexDirection='row'>
        <ListCard title={'Act'} array={Act} />
        <ListCard title={'Ordinance'} array={Ordinance} />
        <ListCard title={'Rules'} array={Rules} />
      </MainContainer>
    </>
  );
};

export default ActOrdinaceRule;
