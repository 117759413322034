import React from 'react';
import { ListItemContainer } from '../act-ordinace-styled';
import { useNavigate } from 'react-router-dom';

const ListItem = ({ data }) => {
  const navigate = useNavigate();
  return (
    <ListItemContainer
      onClick={() => {
        navigate(`detail/${data.type_name.toLowerCase()}/${data.id}`);
      }}
    >
      <img src={require('../../../assets/garentee.png')} alt='' />
      <span>{data.detail}</span>
    </ListItemContainer>
  );
};

export default ListItem;
