import styled from 'styled-components';

export const PrevBtn = styled.div`
  background-color: ${({ isFirst }) => (isFirst ? 'transparent' : '#eff3f8')};
  color: #242f51;
  border: ${({ isFirst }) =>
    isFirst ? '1px solid #242f51' : '1px solid transparent'};
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  opacity: ${({ isFirst }) => (isFirst ? 0.3 : 1)};
  &:active {
    transform: ${({ isFirst }) => (isFirst ? 'scale(1)' : 'scale(0.8)')};
  }
`;
export const NextBtn = styled.div`
  background-color: ${({ isLast }) => (isLast ? 'transparent' : '#eff3f8')};
  color: #242f51;
  border: ${({ isLast }) =>
    isLast ? '1px solid #242f51' : '1px solid transparent'};
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  opacity: ${({ isLast }) => (isLast ? 0.3 : 1)};
  &:active {
    transform: ${({ isLast }) => (isLast ? 'scale(1)' : 'scale(0.8)')};
  }
`;
