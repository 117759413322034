import { Close } from '@mui/icons-material';
import { Grid, IconButton, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAppointment,
  getAppointmentSlot,
} from '../../../redux-store/home/actionCreator';
import { setAppointment } from '../../../redux-store/home/homeReducer';
import {
  Btn,
  Container,
  ErrorText,
  Input,
  LoginRegistrationContainer,
  TextArea,
} from '../../style/StyleComponent';
import { AppointmentContainer } from './appointmentStyle';
import { email, name, phoneNumber } from '../../../utils/formValidation';
import ThanksPopup from '../popup/services/ThanksPopup';

const Appointment = () => {
  const dispatch = useDispatch();
  const appointmentSlot = useSelector((state) => state.home.appointmentSlot);
  const appointment = useSelector((state) => state.home.appointment);
  const [thankYou, setThankYou] = useState(false);
  const [data, setData] = useState({
    appointment_slot_id: '',
    client_name: '',
    client_phone: '',
    client_email: '',
    appointment_detail: '',
    appointmentType: 'call_appointment',
    date: '',
  });
  const [error, setError] = useState({
    client_name: '',
    client_phone: '',
    client_email: '',
  });
  useEffect(() => {
    dispatch(getAppointmentSlot());
  }, []);

  const getSlotInFormate = ({ time = '' }) => {
    const tempTime = time.replace(':00', '').split(':');
    return tempTime[0] > 12
      ? `${tempTime[0] % 12}:${tempTime[1]}`
      : `${tempTime[0]}:${tempTime[1]}`;
  };

  return (
    <>
      <Modal open={thankYou}>
        <ThanksPopup setThanks={setThankYou} />
      </Modal>
      <AppointmentContainer appointment={appointment}>
        <LoginRegistrationContainer width={'30%'}>
          <div
            style={{
              display: 'inherit',
              flexDirection: 'inherit',
            }}
          >
            <IconButton
              style={{
                alignSelf: 'flex-end',
              }}
              onClick={() => {
                dispatch(setAppointment());
                setData({
                  appointment_slot_id: '',
                  client_name: '',
                  client_phone: '',
                  client_email: '',
                  appointment_detail: '',
                  appointmentType: '',
                  date: '',
                });
                setError({
                  client_name: '',
                  client_phone: '',
                  client_email: '',
                });
              }}
            >
              <Close
                style={{
                  color: '#fff',
                }}
              />
            </IconButton>
            <h2>Appointment</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log({ data, error });
                if (!Object.values(error).every((i) => i)) {
                  dispatch(
                    createAppointment(data, () => {
                      setThankYou(true);
                      dispatch(setAppointment());
                    })
                  );
                }
              }}
            >
              <Input
                id='name'
                required
                type='text'
                label='Name'
                value={data.client_name}
                placeholder='Name'
                onChange={(e) => {
                  setData({ ...data, client_name: e.target.value });
                  if (name.test(e.target.value)) {
                    setError((prev) => ({
                      ...prev,
                      client_name: '',
                    }));
                  } else {
                    setError((prev) => ({
                      ...prev,
                      client_name:
                        'Name should contain only alphabet and space',
                    }));
                  }
                }}
              />
              <ErrorText>{error.client_name}</ErrorText>
              <Input
                id='phone'
                required
                type='number'
                label='Phone'
                value={data.client_phone}
                placeholder='Phone'
                onChange={(e) => {
                  setData({ ...data, client_phone: e.target.value });
                  if (
                    phoneNumber.test(e.target.value) &&
                    (e.target.value.length >= 11 || e.target.value.length <= 13)
                  ) {
                    setError((prev) => ({
                      ...prev,
                      client_phone: '',
                    }));
                  } else {
                    setError((prev) => ({
                      ...prev,
                      client_phone: 'Invalid phone number format',
                    }));
                  }
                }}
              />
              <ErrorText>{error.client_phone}</ErrorText>
              <Input
                id='email'
                required
                type='email'
                label='Email'
                value={data.client_email}
                placeholder='Email'
                onChange={(e) => {
                  setData({ ...data, client_email: e.target.value });
                  if (email.test(e.target.value)) {
                    setError((prev) => ({
                      ...prev,
                      client_email: '',
                    }));
                  } else {
                    setError((prev) => ({
                      ...prev,
                      client_email: 'Invalid email address',
                    }));
                  }
                }}
              />
              <ErrorText>{error.client_email}</ErrorText>
              <TextArea
                required
                value={data.appointment_detail}
                placeholder='Appointment Detail'
                onChange={(e) =>
                  setData({ ...data, appointment_detail: e.target.value })
                }
              />
              <Container
                justifyContent='flex-start'
                alignItem={true}
                width='auto'
                marginTop={'15px'}
              >
                <Input
                  required
                  id='typeCall'
                  width='auto'
                  name='type'
                  checked={data.appointmentType === 'call_appointment'}
                  type={'radio'}
                  marginBottom='0px'
                  onChange={(e) =>
                    setData({ ...data, appointmentType: 'call_appointment' })
                  }
                />
                <label htmlFor='typeCall'>Call Appointment</label>
                <Input
                  required
                  id='typephysical'
                  width='auto'
                  name='type'
                  checked={data.appointmentType === 'physical_appointment'}
                  type={'radio'}
                  marginBottom='0px'
                  onChange={(e) =>
                    setData({
                      ...data,
                      appointmentType: 'physical_appointment',
                    })
                  }
                />
                <label htmlFor='typephysical'>Physical Appointment</label>
              </Container>
              <Input
                required
                type='date'
                value={data.date}
                onChange={(e) => setData({ ...data, date: e.target.value })}
              />
              {appointmentSlot.filter(
                (i) => i.appointmentType === data.appointmentType
              ).length > 0 && <h4>Appointment Slots</h4>}
              <Grid container gap={'10px'} justifyContent={'center'}>
                {appointmentSlot
                  .filter((i) => i.appointmentType === data.appointmentType)
                  .map((slot, index) => (
                    <Grid
                      key={index}
                      item
                      xs={5}
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '10px',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Input
                        width={'auto'}
                        id={slot.id}
                        name='slot'
                        required
                        type={'radio'}
                        marginBottom='0px'
                        onChange={(e) =>
                          setData({ ...data, appointment_slot_id: slot.id })
                        }
                      />
                      <label htmlFor={slot.id}>{`${getSlotInFormate({
                        time: slot.startTime,
                      })} to ${getSlotInFormate({
                        time: slot.endTime,
                      })}`}</label>
                    </Grid>
                  ))}
              </Grid>
              <Btn
                type='submit'
                marginTop={'20px'}
                id='login-btn'
                // onClick={() => {
                // }}
                fontSize='18px'
              >
                Submit
              </Btn>
            </form>
          </div>
        </LoginRegistrationContainer>
      </AppointmentContainer>
    </>
  );
};

export default Appointment;
