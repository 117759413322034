import styled from 'styled-components';

export const HeroSectionContainer = styled.div`
  height: 100vh;
  background-size: cover;
  background-position: right;
  display: flex;
  flex-direction: column;
  position: relative;
`;
