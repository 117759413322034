import AxiosCall from '../../api/AxiosCall';
import { setAct, setDetails, setOrdinance, setRules } from './ordinaceReducer';
export const getAll = () => (dispatch) => {
  AxiosCall.get('/ordinance/getAllOrdinance')
    .then((res) => {
      if (res.data.success) {
        const data = res.data.data;
        const act = data.filter(
          (item) => item.type_name.toLowerCase() === 'act'
        );
        const ordinance = data.filter(
          (item) => item.type_name.toLowerCase() === 'ordinance'
        );
        const rules = data.filter(
          (item) => item.type_name.toLowerCase() === 'rule'
        );
        console.log({
          act,
          ordinance,
          rules,
        });
        dispatch(setAct(act));
        dispatch(setOrdinance(ordinance));
        dispatch(setRules(rules));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDetailById = (id) => (dispatch) => {
  AxiosCall.get(`/ordinanceDetail/getOrdinanceDetailById/${id}`)
    .then((res) => {
      if (res.data.success) {
        const data = res.data.data;
        dispatch(setDetails(data));
      } else {
        console.log(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
