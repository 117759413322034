import React from 'react';
import { DownloadStyled } from './DownloadStyled';

const Download = ({ align, index, file, children }) => {
  return (
    <DownloadStyled
      href={`${process.env.REACT_APP_FILE_DOMAIN}${file}`}
      download={new Date().getTime() + index + '.pdf'}
      textAlign={align}
      target='_blank'
    >
      {children}
    </DownloadStyled>
  );
};

export default Download;
