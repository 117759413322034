import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  researchAndInsightBlog: [],
  blogs: [],
};

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setResearch: (state, action) => {
      state.researchAndInsightBlog = action.payload;
    },
    setBlogs: (state, action) => {
      state.blogs = action.payload;
    },
  },
});
export const { setResearch, setBlogs } = blogSlice.actions;
export default blogSlice.reducer;
