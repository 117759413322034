import styled from 'styled-components';

export const ServerAndFeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ServicesAndFeeHead = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
  span {
    font-size: 14px;
    color: #000;
    margin-top: 10px;
    line-height: 1.5;
    font-weight: 600;
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 20px;
    }
    span {
      font-size: 12px;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const ServicesAndFeeBody = styled.div`
  display: flex;
  width: 100%;
`;

export const CategoryList = styled.div`
  width: 65%;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const CategoryBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #373f68;
  color: #fff;
  height: fit-content;
  font-weight: bold;
  overflow-x: auto;
`;

export const CategoryBarItems = styled.span`
  padding: 10px 20px;
  background-color: ${({ active }) => (active ? '#fac201' : '#373f68')};
  text-transform: uppercase;
  color: ${({ active }) => (active ? '#373f68' : '#fff')};
  &:hover {
    background-color: #fac201;
    color: #000;
    font-weight: bold;
  }
`;

export const PriceCardContainer = styled.div`
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }

  & > div {
    & > strong {
      font-size: 20px;
    }
    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & > div {
        padding: 10px 20px;
        background-color: #373f68;
        color: #fff;
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 1230px) {
    & > div {
      & > strong {
        font-size: 18px;
      }
      & > div {
        & > span {
          font-size: 14px;
          width: 80%;
        }
      }
      &:last-child {
        & > div {
          padding: 10px 15px;
          font-size: 14px;
        }
        & > span {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 990px) {
    & > div {
      & > strong {
        font-size: 16px;
      }
      & > div {
        & > span {
          font-size: 12px;
        }
      }
      &:last-child {
        & > div {
          padding: 10px 10px;
          font-size: 12px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    & > div {
      & > strong {
        font-size: 14px;
      }
      & > div {
        & > img {
          width: 20px;
        }
      }
    }
  }
  @media (max-width: 375px) {
    margin-right: 0;
    & > div {
      & > div {
        padding: 0px;
      }
      &:last-child {
        & > div {
          padding: 10px 5px;
          font-size: 10px;
        }
        & > span {
          font-size: 10px;
        }
      }
    }
  }
`;
