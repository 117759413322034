import React, { useEffect } from 'react';
import HeroSections from '../../global/components/hero-section/HeroSections';
import BlogsSection from './blogs-section/BlogsSection';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSections pageName={'Blogs'} />
      <BlogsSection />
    </>
  );
};

export default Blogs;
