import styled from 'styled-components';

export const LibraryContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  background-color: #373f68;
  height: 100vh;
  overflow: hidden;
  padding: 0 0 0 2em;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  overflow-y: auto;
  padding: 2em 2em 2em 1em;
`;

export const SearchContainer = styled.div`
  display: Grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(${({ row }) => row}, 1fr);
  width: 100%;
  color: #fff;
  grid-gap: 1em;
  & > input {
    margin: 0;
  }

  & > select {
    width: ${(props) => props.width || '100%'};
    height: 100%;
    margin: 0;
    border: 1px solid #373f68;
    border-radius: 5px;
    padding: ${(props) => props.padding || '5px 10px'};
    font-size: ${(props) => props.fontSize || '14px'};
    color: ${(props) => props.color || '#373f68'};
    background-color: ${(props) => props.bgColor || '#fff'};
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(${({ row }) => row - 1}, 1fr);
  }
`;

export const SearchButtonContainer = styled.div`
  grid-column: 1 / ${(props) => props.gridColumn} span;
  display: flex;
  justify-content: center;
  align-items: center;
  & > button {
    background-color: #373f68;
    color: #fff;
    border: none;
    padding: 1em 3em;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    &:hover {
      background-color: #fcc028;
      color: #fff;
    }
  }
  @media (max-width: 768px) {
    grid-column: 1 / 3 span;
  }
`;
