import React, { useEffect } from 'react';
import HeroSections from '../../global/components/hero-section/HeroSections';
import AboutUsCounter from './components/AboutUsCounter';
import CompanyHistory from './components/CompanayHistory';
import HowWeWork from './components/HowWeWork';
import Team from './components/Team';
import './AboutUs.css';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSections pageName={'About Us'} />
      <CompanyHistory />
      <HowWeWork />
      {/* <Team /> */}
      <AboutUsCounter />
    </>
  );
};

export default AboutUs;
